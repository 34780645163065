

export interface ICancel {
  cancel: (()=>void)|null
}

export function Sleep(ms:number, cancelObj?:ICancel):Promise<void> {
	const cancelObject:ICancel = cancelObj || {} as ICancel
	let timeoutId:NodeJS.Timeout

	const finalize = ()=>{
		cancelObject.cancel = null
		clearTimeout(timeoutId)
	}

	const timeout = new Promise<void>((resolve, reject)=>{
		timeoutId = setTimeout(()=>{
			finalize()
			resolve()
		}, ms)
	})

	const cancel = new Promise<void>((resolve, reject)=>{
		cancelObject.cancel = ()=>{
			finalize()
			reject(new DOMException('User cancelled', 'Cancelled'))
		}
	})

	return Promise.race([timeout, cancel])
}

/*
 let cancelObj = {}
 TPromise(10, )
*/

export function TPromise<T>(ms:number, promise:Promise<T>, cancelObj?:ICancel):Promise<T> {
	const cancelObject:ICancel = cancelObj || {} as ICancel
	let timeoutId:NodeJS.Timeout

	const finalize = ()=>{
		cancelObject.cancel = null
		clearTimeout(timeoutId)
	}

	const timeout = new Promise<T>((resolve, reject)=>{
		timeoutId = setTimeout(()=>{
			finalize()
			reject(new DOMException(`Time out after ${ms}ms`, 'TimeoutError'))
		}, ms)
	})

	const cancel = new Promise<T>((resolve, reject)=>{
		cancelObject.cancel = ()=>{
			finalize()
			reject(new DOMException('User cancelled', 'Cancelled'))
		}
	})

	const task = new Promise<T>((resolve, reject)=>{
		promise.then((result)=>{
			finalize()
			resolve(result)
		}).catch((err)=>{
			finalize()
			reject(err)
		})
	})

	return Promise.race<T>([timeout, cancel, task])
}
