import React from 'react'
import ProgressBar from '../components/progressbar'
import Field from '../components/field'
import NumberButtons from './numberbuttons'
import { connect } from 'react-redux'
import Actions, {AppMode} from '../reducers/actions'
import ErrorIcon from '@material-ui/icons/Clear'
import {CalculateFieldData} from '../sudoku'

type GlobalState = any
type Action = any

const Time = (props:{time:number})=>{
	const seconds = ('00' + props.time % 60).substr(-2)
	const minutes = ('00' + Math.floor(props.time / 60) % 60).substr(-2)
	const hours = Math.floor(props.time / 60 / 24)
	const timeStr = (hours > 0 ? [hours, minutes, seconds] : [minutes, seconds]).join(':')
	return (
		<span className='time'>{timeStr}</span>
	)
}

interface TopBarProps {
	progress:number
}
const TopBarSingle = (props:TopBarProps)=>{
	return (
		<div className='game-header single'>
			<ProgressBar className='game-progress-bar single' horizontal now={props.progress} />
		</div>
	)
}
interface TopBarMultiProps {
	progress:number
	opponentProgress:number
}
const TopBarMulti = (props:TopBarMultiProps)=>{
	return (
		<div className='game-header multi'>
			<ProgressBar className='game-progress-bar multi multi-left' horizontal now={props.progress} />
			<ProgressBar className='game-progress-bar multi multi-rigth' horizontal reverse now={props.opponentProgress} />
		</div>
	)
}

export interface OwnProps {
	show:boolean
	onCellClicked:(index:number)=>void
}
interface StateProps {
	field:number[]
	time:number
	showField:boolean
	progress:number
	opponentProgress:number
	appMode:AppMode
	errorCount:number
	maxErrorCount:number
	opponentErrorCount:number
}
interface DispatchProps {
	onCellClicked:(cell:number)=>void
	onNumberClicked:(n:number)=>void
}
interface Props extends OwnProps, StateProps, DispatchProps {}

const Err = (props:any)=>{
	const style = {
		display: 'flex',
		flexFlow: props.reverse ? 'row-reverse' : 'row',
	}
	const icons = []
	for (let i = 0; i < props.maxErrorCount; i++) {
		icons.push(<ErrorIcon key={i} className={(props.errorCount > i)?'error':'disabled'} fontSize='small'/>)
	}

	return (
		<span style={style}>
			{icons}
		</span>
	)
}

const Board:React.FunctionComponent<Props> = (props:Props)=>{

	const showField = props.showField &&
		((props.appMode === AppMode.netPlaying) || (props.appMode === AppMode.playing))
	
	const progress = showField ? props.progress : 0
	const opponentProgress = showField ? props.opponentProgress : 0
	const isPvp = [
		AppMode.netConnecting,
		AppMode.netWaiting,
		AppMode.netPlaying,
	].includes(props.appMode)
		
	const time = showField ? props.time : 0
	
	return (
		<div className='field-wrapper'>
			<div className='field-center'>
				<div style={{display: 'flex', justifyContent: 'space-between', height: 0}}>
					<Err errorCount={props.errorCount} maxErrorCount={props.maxErrorCount}/>
					{isPvp && <Err errorCount={props.opponentErrorCount} maxErrorCount={props.maxErrorCount} reverse/>}
				</div>
				{isPvp ? <div>vs</div> : <Time time={time}/>}
				{isPvp ? 
					<TopBarMulti progress={progress} opponentProgress={opponentProgress}/>:
					<TopBarSingle progress={progress}/>
				}
				<Field show={showField} field={props.field} onCellClicked={props.onCellClicked}/>
				<NumberButtons onClick={props.onNumberClicked}/>
			</div>
		</div>
	)

}

const stateToProps = (state:GlobalState):StateProps=>{
	const opponentField = CalculateFieldData(state.sudoku.initialField, state.sudoku.field, state.net.opponent.visibleField)
	const opponentErrorCount = state.net.opponent.errorCount as number
	return {
		field: state.sudoku.visibleField,
		time: state.sudoku.time,
		showField: state.sudoku.showField,
		progress: state.sudoku.progress,
		appMode: state.app.mode,
		errorCount: state.sudoku.errorCount,
		maxErrorCount: state.sudoku.maxErrorCount,
		opponentProgress: opponentField.progress,
		opponentErrorCount: opponentErrorCount,
	}
}
const dispatchToProps = (dispatch:React.Dispatch<Action>, props:OwnProps):DispatchProps=>{
	return {
		onCellClicked:(cell:number)=>{
			dispatch({type: Actions.sudokuSelectCell, index: cell})
		},
		onNumberClicked:(n:number)=>{
			dispatch({type: Actions.sudokuSetNumber, 'number': n})
			dispatch({type: Actions.saveGame})
		},
	}
}

export default connect<StateProps, DispatchProps, OwnProps>(stateToProps, dispatchToProps)(Board)
