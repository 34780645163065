import React from 'react'
import ReactDOM from 'react-dom'
import './style.css'
import Game from './game'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reducer from './reducer'
import AppBar from './components/appbar'
import { HashRouter as Router, Route } from 'react-router-dom'

import middleware from './reducers/middleware'

const store = createStore(reducer, applyMiddleware(middleware))
ReactDOM.render(
	<Provider store={store}>
		<Router hashType='noslash'>
			<AppBar/>
			<Route path={'/:roomId?'} component={Game} />
		</Router>
	</Provider>,
document.getElementById('root'))
