import Actions, { AppMode } from './actions'
import {loadSettings, saveSettings, loadGame, saveGame} from '../persistent'
import { createRoomTimed } from '../api'

const SavedGame = {
	visibleField: '',
	initialField: '',
	field: '',
	time: 0,
	complexity: 0,
	errorCount: 0,
}

const DEV = process.env.NODE_ENV === 'development'

const urlCreateRoom = (complexity)=>{
	const url = new URL(window.location)
	url.hash = ''
	url.password = ''
	url.pathname = '/create'
	url.search = ''
	url.searchParams.set('c', complexity)
  url.username = ''

	if (DEV){
		// TODO: remove
		url.port = 3001
	}

	return url.toString()
}


const mobileLink = (room)=>{
	const url = new URL(window.location)
	url.hash = room
	url.password = ''
	url.pathname = ''
	url.search = ''
	url.protocol = 'sudoku:'
  url.username = ''

	return url.toString()
}

const saveGameFromState = (id, state)=>{
	saveGame(id, {
		visibleField: state.sudoku.visibleField.join(''),
		initialField: state.sudoku.initialField.join(''),
		field: state.sudoku.field.join(''),
		time: state.sudoku.time,
		complexity: state.sudoku.complexity,
		errorCount: state.sudoku.errorCount,
	})
}

const middleware = (store) => {
	let component = null
	return next => action => {

		if (DEV) {
			console.group(Actions[action.type] || action.type)
			console.info('dispatching', action)
			console.groupEnd()
		}

		switch (action.type) {

			case Actions.appInitialize: {
				const settings = loadSettings()
				if (settings) {
					store.dispatch({type:Actions.appSetSettings, settings})
				}
				if (action.withRoom) {
					//window.location.replace(mobileLink(action.withRoom))
					/*
					setTimeout(function() { 
						if (!document.webkitHidden) {
							location.replace("itms://itunes.apple.com/us/app/apple-store");
						}
					}, 25);
					*/
				}
				else {
					store.dispatch({type:Actions.showSettings, show:true})
					const savedGame = loadGame('single')
					if (savedGame) {
						store.dispatch({type:Actions.sudokuSetField, ...savedGame})
						store.dispatch({type:Actions.appSetMode, mode:AppMode.playing})
					}
					else {
						const complexity = store.getState().settings.complexity
						store.dispatch({type:Actions.newGame, complexity})
					}
				}
				break
			}

			case Actions.netComponentDidConnect: {
				component = action.component
				store.dispatch({type: Actions.appSetMode, mode: AppMode.netWaiting})
				break
			}
			case Actions.netComponentReset: {
				component = null
				break
			}
			
			case Actions.netCreateRoom: {
				createRoomTimed(action.complexity)
				.then(response=>{
					store.dispatch({type: Actions.roomCreated, id:response.id, url:response.socket_url})
					store.dispatch({type: Actions.netJoinRoom, id:response.id})
				}).catch(console.error)
				break
			}
			case Actions.newGame: {
				window.location.hash = ''
				break
			}
			case Actions.netJoinRoom: {
				if (action.id) {
					//window.location.replace(mobileLink(action.id))
				}
				window.location.hash = action.id
				break
			}
			case Actions.netUpdateField: {
				component && component.send({type:'field', data:action.data})
				break
			}
			case Actions.netUserInfo: {
				component && component.send({type:'userInfo', userInfo:action.userInfo})
				break
			}
			case Actions.saveGame: {
				const state = store.getState()
				if (state.app.mode === AppMode.playing) {
					saveGameFromState('single', state)
				}
				else if (state.app.mode === AppMode.netPlaying) {
					const id = 'multi-' + state.net.roomId
					saveGameFromState(id, state)
				}
				break
			}
			case Actions.netRoomUpdated: {
				if (action.users.length > 1) {
					if (store.getState().app.mode === AppMode.netWaiting) {
						store.dispatch({type:Actions.appSetMode, mode:AppMode.netPlaying})
					}
				}
				break
			}
			case Actions.loadNetGame: {
				const savedGame = loadGame('multi-'+action.roomId)
				if (savedGame) {
					store.dispatch({type:Actions.sudokuSetField, ...savedGame})
				}
				break
			}
		}
		
		let result = next(action)

		// Post Actions

		switch(action.type) {
			case Actions.setComplexity:
			case Actions.setComplexityPvp: {
				saveSettings(store.getState().settings)
				break
			}
			case Actions.newGame: {
				store.dispatch({type:Actions.saveGame})
				break
			}
		}

		return result 
	}
}

export default middleware
