const version = process.env.REACT_APP_VERSION

export const loadSettings = ()=>{
  let loaded
  try {
    loaded = JSON.parse(localStorage.getItem('gameSettings'))
  }
  catch (e) {
    return undefined
  }
  if (!loaded) {
    return undefined
  }
  if (version !== loaded.version) {
    return undefined
  }
  return loaded.data
}

export const saveSettings = (settings)=>{
	try {
		const toSave = { version, data:settings }
		localStorage.setItem('gameSettings', JSON.stringify(toSave))
	}
	catch (e) {}
}

export const loadGame = (id)=>{
  const key = 'savedGame-' + id
  let loaded
  try {
    loaded = JSON.parse(localStorage.getItem(key))
  }
  catch (e) {
    return undefined
  }
  if (!loaded) {
    return undefined
  }
  if (version !== loaded.version) {
    return undefined
  }
  return loaded.data
}

export const saveGame = (id, game)=>{
  const toSave = { version, data:game }
  const key = 'savedGame-' + id
	try {
		localStorage.setItem(key, JSON.stringify(toSave))
	}
	catch (e) {}
}
