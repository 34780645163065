import Presets from '../presets.json'
import Actions from './actions'
import {CalculateFieldData, zeroField} from '../sudoku'

const InitialState = {
	selectedIndex: -1,
	visibleField: [...zeroField],
	initialField: [...zeroField],
	initialHints: 0,
	field: [...zeroField],
	erroredIndices: [],
	openHints: 0,
	errorCount: 0,
	maxErrorCount: 3,
	progress: 0,
	time: 0,
	complexity: 0,
	showField: true,
}

export default (state, action)=>{
	if (state === undefined) {
		return {...InitialState}
	}
	switch(action.type) {
		case Actions.tick: {
			if (state.showField) {
				return {...state, time:state.time+1}
			}
			return state
		}
		case Actions.sudokuSelectCell: {
			return {...state, selectedIndex: action.index}
		}
		case Actions.sudokuSetNumber: {
			if (!state.showField) {
				return state
			}
			if (state.initialField[state.selectedIndex] !== 0) {
				return state
			}
			if (state.visibleField[state.selectedIndex] === action.number) {
				return state
			}
			let errorCount = state.errorCount
			if (state.field[state.selectedIndex] !== 0 && action.number !== 0) {
				if (state.field[state.selectedIndex] !== action.number) {
					errorCount++
				}
			}
			const visibleField = [...state.visibleField]
			visibleField[state.selectedIndex] = action.number
			const fieldData = CalculateFieldData(state.initialField, state.field, visibleField)
			return {
				...state,
				...fieldData,
				errorCount,
			}
		}
		case Actions.newGame: {
			const complexityKey = Object.keys(Presets)[action.complexity]
			const levels = Presets[complexityKey]
			const [initialField, field] = levels[Math.floor(Math.random() * levels.length)]
			action.initialField = initialField
			action.visibleField = initialField
			action.solvedField = field
			action.time = 0
			action.errorCount = 0
		} // fallthrough
		case Actions.sudokuSetField: {
			const initialField = action.initialField.split('').map(i=>+i)
			const solvedField = action.solvedField.split('').map(i=>+i)
			const visibleField = action.visibleField.split('').map(i=>+i)

			const fieldData = CalculateFieldData(initialField, solvedField, visibleField)

			return {
				...state,
				...fieldData,
				errorCount: action.errorCount || 0,
				complexity: action.complexity,
				selectedIndex: -1,
				time: action.time,
			}
		}
		case Actions.sudokuShowField: {
			return {...state, show: action.show}
		}
		default: return state
	}
}
