import React from 'react'

export interface Props {
	variant?:string
	now:number
	horizontal?:boolean
	className?:string
	reverse?:boolean
}

interface State {
}

export default class ProgressBar extends React.Component<Props, State> {
	render() {
		const containerStyle = {
			flexFlow: 'column',
		}
		const style = {
			width: '100%',
			height: '100%',
		}
		if (this.props.horizontal) {
			style['width'] = `${this.props.now}%`
			containerStyle['flexFlow'] = 'row'
		}
		else {
			style['height'] = `${this.props.now}%`
			containerStyle['flexFlow'] = 'column'
		}
		if (this.props.reverse) {
			containerStyle['flexFlow'] = containerStyle['flexFlow']+'-reverse'
		}
		return (
			<div className={this.props.className}>
			<div className='progress-bar'>
				<div className='progress-bar-container' style={containerStyle}>
					<div className={`progress-bar-bar ${this.props.variant}`} style={style}></div>
				</div>
			</div>
			</div>
		)
	}
}
