import React from 'react'
import IconButton from '@material-ui/core/IconButton'

interface OwnProps {
	onClick: (n:number)=>void
}

const row1 = [1, 2, 3, 4, 5]
const row2 = [6, 7, 8, 9]

export default class NumbersComponent extends React.Component<OwnProps> {
	render() {
		return (
			<div className='numbers'>
				<div className='numbersRow'>
					{row1.map(e=>{
						return <IconButton size='small' className='number-button' key={e} onClick={()=>this.props.onClick(e)}>
							<div className='number'>{e}</div>
						</IconButton>
					})}
				</div>
				<div className='numbersRow'>
					{row2.map(e=>{
						return <IconButton size='small' className='number-button' key={e} onClick={()=>this.props.onClick(e)}>
							<div className='number'>{e}</div>
						</IconButton>
					})}
					<IconButton size='small' className='number-button' onClick={_=>this.props.onClick(0)}>
						<div className='number'>X</div>
					</IconButton>
				</div>
			</div>
		)
	}
}
