import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import { connect } from 'react-redux'
import Actions, {AppMode} from './reducers/actions'
import { Paper, Button, Typography } from '@material-ui/core'
import NativeSelect from '@material-ui/core/NativeSelect'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import {CalculateFieldData} from './sudoku'

const Complexities = ['Easy', 'Medium', 'Hard', 'Expert']

const ComplexitySelection = props=>{
	const onChange = (e)=>{
		props.onChange(+e.target.value)
	}
	const value = props.value + ''
	return(
			<NativeSelect aria-label='Complexity' value={value} onChange={onChange}>
					{Complexities.map((c,i)=><option key={i} value={i+''} label={c}>{c}</option>)}
			</NativeSelect>
	)
}

const EndSingle = (props)=>{
  const text = props.won ? 'You Won!' : 'You Lost'
	const [roomId, setRoomId] = useState('')
  return (
  <Paper className='settings-container' style={{outline: 'none'}}>
      <Typography className='settings-title' variant='h4'>{text}</Typography>
        <Typography className='settings-title' variant='h6'>New Game</Typography>
        <div className='settings-group'>
          <ComplexitySelection value={props.complexity} onChange={props.setComplexity}/>
          <Button size='medium' variant='contained' color='primary' onClick={()=>props.newGame(props.complexity)}>Create</Button>
        </div>

        <Typography className='settings-title' variant='h6'>Join Online Game</Typography>
        <div className='settings-group'>
          <OutlinedInput label='Game Code' onChange={e=>setRoomId(e.target.value)}></OutlinedInput>
          <Button size='medium' variant='contained' color='primary' onClick={()=>props.joinRoom(roomId)}>Join</Button>
        </div>

        <Typography className='settings-title' variant='h6'>Create Online Game</Typography>
        <div className='settings-group'>
          <ComplexitySelection value={props.complexityPvp} onChange={props.setComplexityPvp}/>
          <Button size='medium' variant='contained' color='primary' onClick={()=>props.createRoom(props.complexityPvp)}>Create</Button>
        </div>
    </Paper>)
}

const Component = (props)=>{

  const lostSingle = (props.mode === AppMode.playing) && (props.errorCount >= props.maxErrorCount)
  const wonSingle = (props.mode === AppMode.playing) && (props.progress === 100)
  let lostPvp = (props.mode === AppMode.netPlaying) && (props.errorCount >= props.maxErrorCount)
  let wonPvp = !lostPvp && (props.mode === AppMode.netPlaying) && (props.progress === 100)
  if (props.mode === AppMode.netPlaying) {
    const opponentField = CalculateFieldData(props.initialField, props.field, props.opponent.visibleField)
    const opponentLost = (props.opponent.errorCount >= props.maxErrorCount)
    const opponentWon = opponentField.progress === 100
    lostPvp = lostPvp || opponentWon
    wonPvp = !lostPvp && (opponentLost || wonPvp)
  }

  const showModal = lostSingle || wonSingle || lostPvp || wonPvp

  const render = ()=>{
    if (lostSingle) {
      return <EndSingle won={false} {...props}/>
    }
    if (wonSingle) {
      return <EndSingle won={true} {...props}/>
    }
    if (lostPvp) {
      return <EndSingle won={false} {...props}/>
    }
    if (wonPvp) {
      return <EndSingle won={true} {...props}/>
    }
  }


  return (
    <Modal open={showModal}>
      <div>
        {render()}
      </div>
    </Modal>
  )
}

const stateToProps = (state)=>{
  return {
    mode: state.app.mode,
    errorCount: state.sudoku.errorCount,
    maxErrorCount: state.sudoku.maxErrorCount,
    progress: state.sudoku.progress,
		complexity: state.settings.complexity,
    complexityPvp: state.settings.complexityPvp,
    opponent: state.net.opponent,
    initialField: state.sudoku.initialField,
    field: state.sudoku.field,
  }
}

const dispatchToProps = (dispatch)=>{
  return {
		newGame: (complexity)=>{
			dispatch({type:Actions.newGame, complexity})
			dispatch({type:Actions.showSettings, show:false})
		},
		setComplexity: (complexity)=>dispatch({type:Actions.setComplexity, complexity}),
		setComplexityPvp: (complexity)=>dispatch({type:Actions.setComplexityPvp, complexity}),
		createRoom: (complexity)=>{
			dispatch({type:Actions.netCreateRoom, complexity})
			dispatch({type:Actions.showSettings, show:false})
		},
		joinRoom: (roomId)=>{
			dispatch({type:Actions.netJoinRoom, id:roomId})
			dispatch({type:Actions.showSettings, show:false})
		}
  }
}

export default connect(stateToProps, dispatchToProps)(Component)
