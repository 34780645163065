import Actions from './actions'

const InitialState = {
  userId: null,
  roomUsers: [],
  roomId: null,
  opponent: {
    visibleField: [],
  },
  opponentInfo: {},
}

export default (state, action)=>{
	if (state === undefined) {
		return {...InitialState}
	}
	switch(action.type) {
    case Actions.netConnected: {
      return {...state, userId:action.id}
    }
    case Actions.netDiconnected:{
      return {...state, userId:null, roomId:null, roomUsers:[]}
    }
    case Actions.netJoinedRoom: {
      return {...state, roomId:action.id, roomUsers:[]}
    }
    case Actions.netRoomUpdated: {
      return {...state, roomUsers:[...action.users]}
    }
    case Actions.netFieldUpdated: {
      if (state.userId !== action.id) {
        return {...state, opponent:action.data}
      }
      return state
    }
    case Actions.netUserInfoUpdated: {
      if (state.userId !== action.id) {
        return {...state, opponentInfo:action.userInfo}
      }
      return state
    }
    
    case Actions.netBadRoom: {
      console.error('failed to connect to the room ', action.id)
      return state
    }
    case Actions.netFullRoom: {
      console.error('failed to connect to the room ', action.id)
      return state
    }
    case Actions.netJoinRoom: {
      return {...state, roomId: action.id}
    }

    case Actions.netLeftRoom: {
      return {...state, roomId:null, roomUsers:[]}
    }

    default: return state
	}
}
