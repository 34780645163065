import React from 'react'
import { connect } from 'react-redux'
import Actions, {AppMode} from './reducers/actions'
import {getRoomTimed} from './api'

const ReconnectAttempts = 5
const ConnectTimeout = 1 * 1000
const ReconnectTimeout = 1 * 1000

const DEV = process.env.NODE_ENV === 'development'

const urlFromId = (id)=>{
	const url = new URL(window.location)
	url.hash = ''
	url.password = ''
	url.pathname = '/socket'
	url.protocol = (url.protocol === 'https:') ? 'wss' : 'ws'
	url.search = ''
	url.searchParams.set('r', id)
  url.username = ''

	if (DEV){
		// TODO: remove
		url.port = 3001
	}

	return url.toString()
}

const Sleep = async (ms, cancelObj)=>{
	cancelObj = cancelObj || {}
	let timeoutId = null

	const finalize = ()=>{
		cancelObj.cancel = null
		clearTimeout(timeoutId)
	}

	const timeout = new Promise((resolve, reject)=>{
		timeoutId = setTimeout(()=>{
			finalize()
			resolve()
		}, ms)
	})

	const cancel = new Promise((resolve, reject)=>{
		cancelObj.cancel = ()=>{
			finalize()
			reject(new DOMException('User cancelled', 'Cancelled'))
		}
	})

	return Promise.race([timeout, cancel])
}

/*
 let cancelObj = {}
 TPromise(10, )
*/
const TPromise = async (ms, promise, cancelObj)=>{
	cancelObj = cancelObj || {}
	let timeoutId = null

	const finalize = ()=>{
		cancelObj.cancel = null
		clearTimeout(timeoutId)
	}

	const timeout = new Promise((resolve, reject)=>{
		timeoutId = setTimeout(()=>{
			finalize()
			reject(new DOMException(`Time out after ${ms}ms`, 'TimeoutError'))
		}, ms)
	})

	const cancel = new Promise((resolve, reject)=>{
		cancelObj.cancel = ()=>{
			finalize()
			reject(new DOMException('User cancelled', 'Cancelled'))
		}
	})

	const task = new Promise((resolve, reject)=>{
		promise.then((result)=>{
			finalize()
			resolve(result)
		}).catch((err)=>{
			finalize()
			reject(err)
		})
	})

	return Promise.race([timeout, cancel, task])
}

class Connection extends React.Component {
	ws = null
	cancel = null
	
	connect = ()=>{
		if (!this.props.room) {
			return
		}
		this.createConnection(this.props.room).then(ws=>{
			this.ws = ws
			ws.onmessage = this.onMessage
			ws.onclose = this.onClose
			this.props.dispatch({type:Actions.netComponentDidConnect, component:this})
		}).catch(e=>{
			if (e.name === 'Cancelled') {
				// Do nothing if the user cancels
			}
			else {
				this.props.dispatch({type:Actions.netErrored, reconnecting:false})
			}
		})
	}

	createConnection = async (roomId)=>{

		const room = await getRoomTimed(roomId)
		const url = room.socket_url

		let attempts = ReconnectAttempts
		const connectTimeout = ConnectTimeout
		const retryInterval = ReconnectTimeout

		// Throws when the port to which the connection is being attempted is being blocked
		const cc = (url)=>new Promise((resolve,reject)=>{
			try {
				const connection = new WebSocket(url)
				connection.onopen = ()=>{
					connection.onclose = undefined
					resolve(connection)
				}
				connection.onclose = ()=>reject(new DOMException('Network Error', 'NetworkError'))
			}
			catch (e) {
				reject(e)
			}
		})
		
		let connection = null
		while (!connection) {
			try {
				connection = await TPromise(connectTimeout, cc(url), this)
			}
			catch (e) {
				console.warn(e.name)
				attempts--
				if(e.name === 'TimeoutError') {
					// Try to reconnect immediately
				}
				else {
					await Sleep(retryInterval, this)
				}
				if (attempts === 0) {
					throw e
				}
			}
		}
		return connection
	}
	
	componentDidUpdate = (prevProps, prevState, snapshot)=>{
		if (prevProps.room !== this.props.room) {
			this.close()
			this.connect()
		}
	}

	componentDidMount = ()=>{
		this.connect()
	}

	componentWillUnmount = ()=>{
		this.props.dispatch({type:Actions.netComponentReset})
		this.close()
	}

	onClose = (closeEvent)=>{
		this.ws = null
		if (!closeEvent.wasClean) {
			this.props.dispatch({type:Actions.netErrored, reconnecting:true})
			this.connect()
		}
		else {
			this.props.dispatch({type:Actions.netDiconnected})
		}
	}

	onMessage = (message)=>{
		const netMessage = JSON.parse(message.data)
		this.props.dispatch(netMessage)
	}
	send = (action)=>{
		this.ws && this.ws.send(JSON.stringify(action))
	}
	close = ()=>{
		this.cancel && this.cancel()
		if (this.ws) {
			this.ws.onClose = undefined
			this.ws.close()
			this.ws = null
		}
	}
	render() {return null}
}

class MultiplayerComponent extends React.Component {
	connection = null

	send = (m)=>{
		if (this.props.gameMode !== AppMode.playing) {
			if (this.connection.ws.readyState === WebSocket.OPEN) {
				this.connection.send(m)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.visibleField !== prevProps.visibleField) {
			this.props.dispatch({type:Actions.netUpdateField,
				data:{
					visibleField: this.props.visibleField,
					errorCount: this.props.errorCount,
				}})
		}
	}
	render() {
		return (
			<Connection room={this.props.roomId} dispatch={this.props.dispatch}>
				{this.props.children}
			</Connection>
		)
	}
}

const stateToProps = (state)=>{
	return {
		visibleField: state.sudoku.visibleField,
		errorCount: state.sudoku.errorCount,
		gameMode: state.app.gameMode,
	}
}

const dispatchToProps = (dispatch)=>{
	return {
		dispatch: dispatch,
	}
}

const Multiplayer = connect(stateToProps, dispatchToProps)(MultiplayerComponent)

export default Multiplayer
