import {Sleep, TPromise, ICancel} from './utils/promise'

const DEV = process.env.NODE_ENV === 'development'

const complexityToNumber = (complexity:string):number=>{
	switch (complexity) {
		case 'easy': return 0
		case 'medium': return 1
		case 'hard': return 2
		case 'expert': return 3
		default: throw new DOMException('Complexity string should be in range one of ["easy","medium","hard","expret"]', 'InvalidCopmlexity')
	}
}

const complexityToString = (complexity:number):string=>{
	switch (complexity) {
		case 0: return 'easy'
		case 1: return 'medium'
		case 2: return 'hard'
		case 3: return 'expert'
		default: throw new DOMException('Complexity should be in range 0-3', 'InvalidCopmlexity')
	}
}

const TIMEOUT = 10000

const HOSTNAME = DEV ? 'http://localhost:3301/' : 'https://sudoku.teivaz.com/'
const API_PATH = 'api/v1/'
const URLS = {
	room: HOSTNAME + API_PATH + 'room/',
}

export interface Room {
	id:string
	complexity:string
	connected_users:number
	socket_url:string
}

const createRoom = async (complexity:number):Promise<Room>=>{
	const complexityStr = complexityToString(complexity)
	const body = new URLSearchParams()
	body.append('complexity', complexityStr)
	const result = await fetch(URLS.room, {method: 'POST', body: body.toString(), headers: {
		'Content-Type': 'application/x-www-form-urlencoded'
	}})
	if (!result.ok) {
		throw new DOMException('Failed to create room', 'ApiError')
	}
	const resultObject = await result.json()
	return resultObject as Room
}
const createRoomTimed = (complexity:number, cancelObj?:ICancel)=>TPromise(TIMEOUT, createRoom(complexity), cancelObj)

const getRoom = async (roomId:string):Promise<Room|null>=>{
	const params = new URLSearchParams()
	params.append('id', roomId)
	const result = await fetch(URLS.room + '?' + params.toString())
	if (result.status === 404) {
		return null
	}
	if (!result.ok) {
		throw new DOMException('Failed to get room', 'ApiError')
	}
	const resultObject = await result.json()
	return resultObject as Room
}
const getRoomTimed = (roomId:string, cancelObj?:ICancel)=>TPromise(TIMEOUT, getRoom(roomId), cancelObj)

const sleep = async ()=>{
	await Sleep(3000)
}

const fail = async ()=>{
	throw {message:'sample'}
}

const failTimeout = ()=>TPromise(1000, new Promise(()=>{}))

export {
	createRoom,
	createRoomTimed,

	getRoom,
	getRoomTimed,
	
	sleep,
	fail,
	failTimeout,
}
