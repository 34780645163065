import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import {connect} from 'react-redux'
import Actions from '../reducers/actions'

const AppBarComponent = (props)=>{
  return(
  <AppBar position='sticky' color='default'>
    <Toolbar>
      <IconButton onClick={()=>props.showSettings(!props.settingsShown)} edge='start' color='inherit' aria-label='Menu'>
          =
      </IconButton>
      {props.settingsShown?
      <div className='version'><a href='/index.html'>Version {process.env.REACT_APP_VERSION}</a></div>:
      <div className='version'>Version {process.env.REACT_APP_VERSION}</div>
      }
    </Toolbar>
  </AppBar>
  )
}

const StateToProps = (state)=>{
  return {
    settingsShown: state.app.showSettings
  }
}

const DispatchToProps = (dispatch)=>{
  return {
    showSettings: (show)=>dispatch({type: Actions.showSettings, show}),
  }
}

export default connect(StateToProps, DispatchToProps)(AppBarComponent)
