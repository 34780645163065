import React from 'react'
import Button from '@material-ui/core/Button'
import SnackbarContent from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import { connect } from 'react-redux'
import Actions from '../reducers/actions'
import CloseIcon from '@material-ui/icons/Close'

const SnackBar = (props)=>{
  const copy = ()=>{
    /*
    var textField = document.createElement('textarea')
    textField.innerText = props.url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    */
    navigator.clipboard.writeText(props.url).then().catch()
  }
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    props.close()
  }

  return (
      <SnackbarContent
        open={true}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{props.text}</span>}
        action={[
          <Button onClick={copy}><span>Copy URL</span></Button>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
  )
}

const dispatchToProps = (dispatch, ownProps) => {
  return {
    close: ()=>dispatch({type: Actions.removeMessage, id:ownProps.id})
  }
}

export default connect(undefined, dispatchToProps)(SnackBar)
