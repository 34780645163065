import Actions, {AppMode} from './actions'

const InitialState = {
	mode: AppMode.initialize,
	showSettings: false,
	messages: [],
}

const urlFromId = (id)=>{
	const url = new URL(window.location)
	url.hash = id
	url.password = ''
	url.pathname = '/'
	url.search = ''
  url.username = ''

	return url.toString()
}

export default (state, action)=>{
	if (state === undefined) {
		return {...InitialState}
	}
	switch(action.type) {
		case Actions.appSetMode: {
			return {...state, mode: action.mode}
		}
		case Actions.showSettings: {
			return {...state, showSettings: action.show}
		}
		case Actions.netCreateRoom:
		case Actions.netJoinRoom: {
			return {...state, mode: AppMode.netConnecting}
		}
		case Actions.newGame: {
			return {...state, mode: AppMode.playing}
		}
		case Actions.roomCreated: {
			const message = {
				id: 'roomCreated',
				text: `Room created (${action.id})`,
				url: urlFromId(action.id),
			}
			return {...state, messages: [...state.messages, message]}
		}
		case Actions.addMessage: {
			return {...state, messages: [...state.messages, action.message]}
		}
		case Actions.removeMessage: {
			return {...state, messages: state.messages.filter(m=>m.id!==action.id)}
		}
		default: return state
	}
}
