
enum Actions {
	appInitialize, // {withRoom:roomId}
	appSetSettings, // {settings}
	setComplexity, // {complexity}
	setComplexityPvp, // {complexity}
	sudokuSelectCell, // {index}
	newGame, // {complexity}
	roomCreated, // {id, url}
	addMessage, // {message:{id,text,url?}}
	removeMessage, // {id}
	showSettings, // {show}
	notifyUser, // {text}
	saveGame, //
	loadGame, //
	tick,

	netComponentDidConnect, // {comoponent}
	netComponentReset, // {}
	netErrored, // {reconnecting}
	netDiconnected, // {}

	loadNetGame = 'net.loadNetGame', // {roomId}

	appSetMode = 'app.setMode', // {mode}	
	
	sudokuSetNumber = 'sudoku.setNumber', // {number}
	sudokuSetField = 'sudoku.setField', // {field, initialField, visibleField?, complexity, time}
	sudokuShowField = 'sudoku.showField', // {show}
	

	// Server-client Actions:
	netConnected = 'net.connected', // {id: userId}
	netJoinedRoom = 'net.joinedRoom', // {id: roomId, complexity}
	netRoomUpdated = 'net.roomUpdated', // {users: userId[]}
	netFieldUpdated = 'net.fieldUpdated', // {id, data}
	netUserInfoUpdated = 'net.userInfoUpdated', // {id, userInfo}
	netBadRoom = 'net.badRoom', // {id: roomId}
	netFullRoom = 'net.fullRoom', // {id: roomId}
	
	// not used at the moment
	netLeftRoom = 'net.leftRoom', // {id:roomId}

	// Client-server Actions:
	netCreateRoom = 'net.createRoom', // {complexity}
	netJoinRoom = 'net.joinRoom', // {id: roomId}
	netUpdateField = 'net.updateField', // {data}
	netUserInfo = 'net.userInfo', // {userInfo}
}
export default Actions

export enum AppMode {
	initialize,
	playing,
	netConnecting,
	netWaiting,
	netPlaying = 'netPlaying',
}
