import { combineReducers } from 'redux'

import app from './reducers/app'
import sudoku from './reducers/sudoku'
import settings from './reducers/settings'
import net from './reducers/net'

export default combineReducers({
	app, sudoku, settings, net
})
