
/* Common utils */

const difference = (setA, setB)=>{
	var result = new Set(setA)
	for (var elem of setB) {
		result.delete(elem)
	}
	return result
}

export const zeroField = '000000000000000000000000000000000000000000000000000000000000000000000000000000000'.split('').map(i=>+i)

/* Index-related functions */

export const RowIndices = row=>{
	//const offset = row * 9
	//return [ 0, 1, 2, 3, 4, 5, 6, 7, 8].map(i=>i+offset)
	return [[0,1,2,3,4,5,6,7,8],[9,10,11,12,13,14,15,16,17],[18,19,20,21,22,23,24,25,26],[27,28,29,30,31,32,33,34,35],[36,37,38,39,40,41,42,43,44],[45,46,47,48,49,50,51,52,53],[54,55,56,57,58,59,60,61,62],[63,64,65,66,67,68,69,70,71],[72,73,74,75,76,77,78,79,80]][row]
}
export const ColIndices = col=>{
	//const offset = col
	//return [ 0, 9,18,27,36,45,54,63,72].map(i=>i+offset)
	return [[0,9,18,27,36,45,54,63,72],[1,10,19,28,37,46,55,64,73],[2,11,20,29,38,47,56,65,74],[3,12,21,30,39,48,57,66,75],[4,13,22,31,40,49,58,67,76],[5,14,23,32,41,50,59,68,77],[6,15,24,33,42,51,60,69,78],[7,16,25,34,43,52,61,70,79],[8,17,26,35,44,53,62,71,80]][col]
}
export const BoxIndices = box=>{
	//const offset = Math.floor(box / 3) * 3 * 9 + (box % 3) * 3
	//return [ 0, 1, 2, 9,10,11,18,19,20].map(i=>i+offset)
	return [[0,1,2,9,10,11,18,19,20],[3,4,5,12,13,14,21,22,23],[6,7,8,15,16,17,24,25,26],[27,28,29,36,37,38,45,46,47],[30,31,32,39,40,41,48,49,50],[33,34,35,42,43,44,51,52,53],[54,55,56,63,64,65,72,73,74],[57,58,59,66,67,68,75,76,77],[60,61,62,69,70,71,78,79,80]][box]
}
export const RowsForBox = box=>{
	return [[0,1,2],[0,1,2],[0,1,2],[3,4,5],[3,4,5],[3,4,5],[6,7,8],[6,7,8],[6,7,8]][box]
}
export const ColsForBox = box=>{
	return [[0,1,2],[3,4,5],[6,7,8],[0,1,2],[3,4,5],[6,7,8],[0,1,2],[3,4,5],[6,7,8]][box]
}
export const BoxesForRow = row=>{
	return [[0,1,2],[0,1,2],[0,1,2],[3,4,5],[3,4,5],[3,4,5],[6,7,8],[6,7,8],[6,7,8]][row]
}
export const ColsForRow = row=>{
	return [0,1,2,3,4,5,6,7,8]
}
export const BoxesForCol = col=>{
	return [[0,3,6],[0,3,6],[0,3,6],[1,4,7],[1,4,7],[1,4,7],[2,5,8],[2,5,8],[2,5,8]][col]
}
export const RowsForCol = col=>{
	return [0,1,2,3,4,5,6,7,8]
}
export const ToIndex = (row, col)=>{
	return row * 9 + col
}
export const IsCompleteBlock = elements=>{
	const nonZeroElements = elements.filter(e=>e!==0)
	return (new Set(nonZeroElements)).size === 9
}
export const IsValidBlock = elements=>{
	const nonZeroElements = elements.filter(e=>e!==0)
	return (new Set(nonZeroElements)).size === nonZeroElements.length
}
export const ExpandIndex = index=>{
	const row = Math.floor(index / 9)
	const col = index % 9
	const box = Math.floor(col / 3) + 3 * Math.floor(row / 3)
	return {row, col, box}
}
export const BlocksIndices = index=>{
	const {row, col, box} = ExpandIndex(index)
	return [...RowIndices(row), ...ColIndices(col), ...BoxIndices(box)]
}

const Elements = [1, 2, 3, 4, 5, 6, 7, 8, 9]
const ElementsSet = new Set(Elements)

const Indices = [0, 1, 2, 3, 4, 5, 6, 7, 8]
const IndicesSet = new Set(Indices)

const AllIndices = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80]
const AllIndicesSet = new Set(AllIndices)

/* Field-related functions */

export const CalculateFieldData = (initialField, field, visibleField)=>{
	visibleField = visibleField || initialField
	const totalElements = field.length
	const initialHints = initialField.reduce((a,v,i)=>a+(field[i]===v),0)
	const openHints = visibleField.reduce((a,v,i)=>a+(field[i]===v),0)
	const progress = (openHints - initialHints) / (totalElements - initialHints) * 100
	const erroredIndices = visibleField.reduce((a,v,i)=>{
		if (v === 0) {return a}
		if (v !== field[i]) {a.push(i)}
		return a
	}, [])

	return {
		initialField,
		field,
		visibleField,
		initialHints,
		openHints,
		progress,
		erroredIndices,
	}
}

/* Basic techniques */

export const DeduceByMissing = field=>index=>{
	const blocksElements = BlocksIndices(index).map(i=>field[i])
	const nonZeroElements = blocksElements.filter(e=>e!==0)
	return [...difference(ElementsSet, new Set(nonZeroElements))]
}

export const DeduceByEliminationInBoxes = field=>number=>{
	return Indices.map((acc, box)=>{
		if (!BoxIndices(box).map(i=>field[i]).includes(number)) {
			const rowResult = []
			for (var row in RowsForBox(box)) {
				if (!RowIndices(row).map(i=>field[i]).includes(number))
				rowResult.push(row)
			}
			const colResult = []
			for (var col in ColsForBox(box)) {
				if (!ColIndices(col).map(i=>field[i]).includes(number))
				colResult.push(col)
			}
			const resultIndices = []
			rowResult.forEach(row=>colResult.forEach(col=>resultIndices.push(ToIndex(row, col))))
			
		}
		return acc
	}, [])
}
