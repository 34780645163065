import React from 'react'
import { ExpandIndex } from '../sudoku'
import { connect } from 'react-redux'

type GlobalState = any
type Action = any

export interface OwnProps {
	value:number
	col:number
	row:number
	box:number
	index:number
	onClick:()=>void
}
interface StateProps {
	errored:boolean
	active:number
}
interface DispatchProps {
}
interface Props extends OwnProps, StateProps, DispatchProps {}
interface State {}

class CellComponent extends React.Component<Props, State> {
	render() {
		const errored = this.props.errored ? 'errored' : ''
		return (
			<div onClick={this.props.onClick} className={`cell col-${this.props.col} row-${this.props.row} selected-${this.props.active} ${errored}`}>
			{(this.props.value !== 0) ? 
				<div className='cell-content'>{this.props.value}</div>:
				<div></div>
			}
			</div>
		)
	}
}

const StateToProps = (state:GlobalState, props:OwnProps):StateProps=>{
	let active = 0
	const errored = state.sudoku.erroredIndices.includes(props.index)
	const selectedIndex = ExpandIndex(state.sudoku.selectedIndex)
	const cellIndex = ExpandIndex(props.index)
	if (state.sudoku.selectedIndex === props.index) {
		active = 2
	}
	else if (selectedIndex.box === cellIndex.box || 
		selectedIndex.col === cellIndex.col ||
		selectedIndex.row === cellIndex.row) {
		active = 1
	}
	return {active, errored}
}
const DispatchToProps = (dispatch:React.Dispatch<Action>, props:OwnProps):DispatchProps=>{
	return {
		//onClick: ()=>dispatch({type: Sudoku.Actions.selectCell, index: props.index})
	}
}
export default connect<StateProps, DispatchProps, OwnProps, GlobalState>(StateToProps, DispatchToProps)(CellComponent)

export class DisabledCell extends React.Component<OwnProps, State> {
	render(){
		return (
			<div className={`cell col-${this.props.col} row-${this.props.row} selected-0`}>
			</div>
		)
	}
}
