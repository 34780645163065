import React, { useState } from 'react'
import { connect } from 'react-redux'
import Actions from './reducers/actions'
import { Paper, Button, Typography } from '@material-ui/core'
import NativeSelect from '@material-ui/core/NativeSelect'
import OutlinedInput from '@material-ui/core/OutlinedInput'

const Complexities = ['Easy', 'Medium', 'Hard', 'Expert']

const ComplexitySelection = props=>{
	const onChange = (e)=>{
		props.onChange(+e.target.value)
	}
	const value = props.value + ''
	return(
			<NativeSelect aria-label='Complexity' value={value} onChange={onChange}>
					{Complexities.map((c,i)=><option key={i} value={i+''} label={c}>{c}</option>)}
			</NativeSelect>
	)
}

const SettingsComponent = (props)=> {
	const [roomId, setRoomId] = useState('')
	return (
		<div className='settings-root'>
			<Paper className='settings'>
				<div className='settings-container'>
					<Typography className='settings-title' variant='h6'>New Game</Typography>
					<div className='settings-group'>
						<ComplexitySelection value={props.complexity} onChange={props.setComplexity}/>
						<Button size='medium' variant='contained' color='primary' onClick={()=>props.newGame(props.complexity)}>Create</Button>
					</div>

					<Typography className='settings-title' variant='h6'>Join Online Game</Typography>
					<div className='settings-group'>
						<OutlinedInput label='Game Code' onChange={e=>setRoomId(e.target.value)}></OutlinedInput>
						<Button size='medium' variant='contained' color='primary' onClick={()=>props.joinRoom(roomId)}>Join</Button>
					</div>

					<Typography className='settings-title' variant='h6'>Create Online Game</Typography>
					<div className='settings-group'>
						<ComplexitySelection value={props.complexityPvp} onChange={props.setComplexityPvp}/>
						<Button size='medium' variant='contained' color='primary' onClick={()=>props.createRoom(props.complexityPvp)}>Create</Button>
					</div>
					
					<Button size='medium' variant='contained' color='primary' onClick={props.close}>Close</Button>
				</div>
			</Paper>
		</div>
	)
}

const StateToProps = (state)=>{
	return {
		complexity: state.settings.complexity,
		complexityPvp: state.settings.complexityPvp,
	}
}

const DispatchToProps = (dispatch)=>{
	return {
		close: ()=>{
			dispatch({type:Actions.showSettings, show:false})
		},
		newGame: (complexity)=>{
			dispatch({type:Actions.newGame, complexity})
			dispatch({type:Actions.showSettings, show:false})
		},
		setComplexity: (complexity)=>dispatch({type:Actions.setComplexity, complexity}),
		setComplexityPvp: (complexity)=>dispatch({type:Actions.setComplexityPvp, complexity}),
		createRoom: (complexity)=>{
			dispatch({type:Actions.netCreateRoom, complexity})
			dispatch({type:Actions.showSettings, show:false})
		},
		joinRoom: (roomId)=>{
			dispatch({type:Actions.netJoinRoom, id:roomId})
			dispatch({type:Actions.showSettings, show:false})
		}
	}
}

export default connect(StateToProps, DispatchToProps)(SettingsComponent)
