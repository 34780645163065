import Actions from './actions'

const InitialState = {
	complexity: 0,
	complexityPvp: 0,
}

export default (state, action)=>{
	if (state === undefined) {
		return {...InitialState}
	}
	switch(action.type) {
		case Actions.appSetSettings: {
			return {...action.settings}
		}
		case Actions.setComplexity: {
				return {...state, complexity: action.complexity}
		}
		case Actions.setComplexityPvp: {
				return {...state, complexityPvp: action.complexity}
		}
		default: return state
	}
}
