import React from 'react'
import { ExpandIndex } from '../sudoku'
import Cell, { OwnProps as CellProps, DisabledCell } from './cell'

type GlobalState = any
type Action = any

export interface OwnProps {
	field:number[]
	show:boolean
	onCellClicked:(index:number)=>void
}
interface StateProps {
}
interface DispatchProps {
}
interface Props extends OwnProps, StateProps, DispatchProps {}
interface State {}

const Row = (row:CellProps[], rowIndex:number)=>(
	<span key={rowIndex} className='row'>
		{row.map((col, ci)=>
			<Cell key={ci} {...col} />)
		}
	</span>
)

const DisabledRow = (row:CellProps[], rowIndex:number)=>(
	<span key={rowIndex} className='row'>
		{row.map((col, ci)=>
			<DisabledCell key={ci} {...col} value={0} />)
		}
	</span>
)

export default class Field extends React.Component<Props, State> {
	render() {
		const rowFn = this.props.show ? Row : DisabledRow
		const rows = this.props.field.reduce<CellProps[][]>((acc, value, index)=>{
			const {col, row, box} = ExpandIndex(index)
			if (col === 0) {
				acc.push([])
			}
			const onClick = ()=>this.props.onCellClicked(index)
			acc[row].push({value, index, row, col, box, onClick})
			return acc
		}, [])
		return (
			<div className='field-container'>
				<div className='field'>
					{rows.map(rowFn)}
				</div>
			</div>
		)
	}
}
