import React from 'react'
import { connect } from 'react-redux'
import NetController from './netcontroller'
import GameBoard from './game/board'
import Actions, {AppMode} from './reducers/actions'
import SnackBar from './components/snackbar'
import Settings from './settings'
import {withRouter} from 'react-router-dom'
import Popup from './popup'

class TickComponent extends React.Component {
	timer = null
	componentDidMount = ()=>{
		this.timer = setInterval(this.props.tick, 1000)
	}
	componentWillUnmount = ()=>{
		clearInterval(this.timer)
		this.timer = null
	}
	render = ()=>null
}

class GameComponent extends React.Component {
	componentDidMount = ()=>{
		this.props.initialize(this.props.match.params.roomId)
	}

	render() {
		const shouldTick = (this.props.appMode === AppMode.playing)

		return (
			<div className='app'>
				<div className='game'>
					{shouldTick && <TickComponent tick={this.props.tick} />}
					<NetController roomId={this.props.match.params.roomId} />
					<Popup/>
					{this.props.messages.map(m=><SnackBar key={m.id} {...m} />)}
					<GameBoard/>
					{this.props.showSettings && <Settings/>}
				</div>
			</div>
		)
	}
}

const Game = withRouter(connect((state)=>{
	return {
		appMode: state.app.mode,
		complexity: state.settings.complexity,
		messages: state.app.messages,
		showSettings: state.app.showSettings,
	}
}, dispatch=>{
	return {
		tick: ()=>dispatch({type:Actions.tick}),
		initialize: (roomId)=>dispatch({type:Actions.appInitialize, withRoom:roomId}),
		newSinglePlayer: (complexity)=>dispatch({type:Actions.newGame, complexity}),
	}
})(GameComponent))

export default Game
